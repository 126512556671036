<template>
  <div
    class="avatar d-flex align-center justify-center font-weight-light-bold white--text"
    :style="{ backgroundColor: color }"
  >
    {{ name }}
  </div>
</template>

<script>
export default {
  name: "NameAvatar",
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  font-size: 1rem;
}
</style>
