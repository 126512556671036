<template>
  <div class="tel-input__container">
    <vue-tel-input
      v-model="phone"
      defaultCountry="US"
      mode="international"
      :dropdownOptions="dropdownOptions"
      @input="handlePhoneChange"
    />
  </div>
</template>

<script>
export default {
  name: "PhoneNumberInput",
  props: {
    phoneNumber: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      phone: this.phoneNumber,
      dropdownOptions: {
        showFlags: true,
        showDialCodeInSelection: true,
        width: "15rem",
        showSearchBox: true,
      },
    };
  },
  watch: {
    phoneNumber: {
      handler(value) {
        this.phone = value;
      },
      immediate: true,
    },
  },
  methods: {
    handlePhoneChange(_, phoneObj) {
      if (phoneObj.valid) {
        this.$emit("changePhone", phoneObj.number, true);
      } else {
        this.$emit("changePhone", phoneObj.number, false);
      }
    },
    populatePhoneInput() {
      this.phone = this.phoneNumber;
    },
  },
};
</script>

<style lang="scss">
.vue-tel-input {
  border: 1px solid rgba(153, 153, 153, 0.9);
  height: 40px;
  border-radius: 4px;
}
.vti__input {
  color: var(--v-text-base);
}
.vti__dropdown,
.vti__dropdown-list {
  background-color: var(--v-background-base);
}
.vti__dropdown:hover {
  background-color: var(--v-hover-base);
}
.vue-tel-input:hover {
  border: 1px solid black;
}
</style>
