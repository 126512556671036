<template>
  <DataLoading v-if="!userLoaded || pageLoading" />
  <div class="detail-container" v-else>
    <v-form ref="newUserForm" class="form_container" @submit.prevent>
      <div class="ml-4">
        <v-row>
          <div>
            <v-switch
              :label="userForm.is_active ? 'Active' : 'Inactive'"
              v-model="userForm.is_active"
              @change="updateIsActive"
              :disabled="readOnly"
            />
          </div>
        </v-row>
        <v-row>
          <div>
            <v-text-field
              :value="userForm.name_first"
              :disabled="!createUser && !editUser"
              label="First name"
              outlined
              dense
              :rules="nameRules"
              @change="setFirstName"
              @focus="closeAlert"
            ></v-text-field>
          </div>
        </v-row>
        <v-row>
          <div>
            <v-text-field
              :value="userForm.name_last"
              :disabled="!createUser && !editUser"
              label="Last name"
              outlined
              dense
              :rules="nameRules"
              @change="setLastName"
              @focus="closeAlert"
            ></v-text-field>
          </div>
        </v-row>
        <v-row>
          <div>
            <v-text-field
              :value="userForm.email"
              :disabled="!createUser && !editUser"
              label="Email"
              outlined
              dense
              :rules="emailRules"
              @change="setEmail"
              @focus="closeAlert"
            ></v-text-field>
          </div>
        </v-row>
        <v-row>
          <div>
            <div v-if="!createUser && !editUser">
              <v-text-field
                :value="userForm.phone"
                label="Phone"
                disabled
                outlined
                dense
              ></v-text-field>
            </div>
            <div v-else class="mb-7 phone-input">
              <PhoneNumberInput
                :phoneNumber="userForm.phone"
                @changePhone="changePhone"
                ref="phoneInput"
              />
            </div>
          </div>
        </v-row>
        <v-row>
          <div>
            <div v-if="!createUser && !editUser">
              <v-text-field
                :value="userForm.time_zone"
                disabled
                label="Timezone"
                outlined
                dense
                min-width="100px"
                @focus="closeAlert"
              ></v-text-field>
            </div>
            <div v-else>
              <v-select
                :value="userForm.time_zone"
                :items="locations"
                :menu-props="{ closeOnContentClick: false, offsetY: true }"
                label="Timezone"
                item-text="name"
                class="mb-10"
                min-width="100px"
                outlined
                :rules="locationRules"
                dense
                hide-details
                @change="setUserLocation"
                @focus="closeAlert"
              />
            </div>
          </div>
        </v-row>
      </div>
      <div>
        <v-alert
          v-model="showAlert"
          type="warning"
          dismissible
          outlined
          rounded
          max-width="880px"
          >{{ errMessage }}</v-alert
        >
      </div>
      <div class="permissions-table">
        <p class="permissions-table__title mb-0 mt-1">Permissions</p>
        <DataTable
          :loading="loading.getUserDetails"
          :headers="permissionHeaders"
          :items="userForm.permissions"
          itemKey="permId"
          :itemsPerPage="-1"
          :hideFooter="true"
        >
          <template v-slot:body="{ items, headers }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td v-for="(header, key) in headers" :key="key">
                  <div
                    class="permission-select"
                    :class="{
                      'remove-underline': !createUser && !editUser,
                    }"
                  >
                    <v-select
                      v-if="header.text === 'Entity type'"
                      v-model="userForm.permissions[index].entity_type"
                      :items="entityTypes"
                      hide-details
                      dense
                      :append-icon="iconVal"
                      :disabled="readOnly"
                      @change="changeEntityType($event, index)"
                      @focus="closeAlert"
                    >
                    </v-select>
                  </div>
                  <div
                    class="permission-select"
                    :class="{
                      'remove-underline': !createUser && !editUser,
                    }"
                  >
                    <v-autocomplete
                      v-if="header.text === 'Entities'"
                      v-model="userForm.permissions[index].entity_id"
                      :items="
                        userForm.permissions[index].entity_type === 'Org'
                          ? userForm.permissions[index].adminOrgs
                          : userForm.permissions[index].adminSites
                      "
                      item-text="name"
                      item-value="id"
                      multiple
                      hide-details
                      dense
                      :append-icon="iconVal"
                      :disabled="readOnly"
                      @change="changeEntitiesSelected($event, index)"
                      @focus="closeAlert"
                    >
                      <template v-slot:prepend-item>
                        <div v-if="userForm.permissions[index].showSelectAll">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                <div class="d-flex">
                                  <b
                                    class="primary--text mr-3 text-decoration-underline pointer"
                                    @click="selectAllItems(index)"
                                  >
                                    Select all
                                  </b>
                                  <b
                                    class="primary--text text-decoration-underline pointer"
                                    @click="clearSelectedItems(index)"
                                  >
                                    Clear
                                  </b>
                                </div>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </template>
                    </v-autocomplete>
                  </div>
                  <div
                    class="d-flex align-center"
                    :class="{
                      'remove-underline': !createUser && !editUser,
                    }"
                  >
                    <div class="permission-select">
                      <v-select
                        v-if="header.text === 'Level'"
                        v-model="userForm.permissions[index].level"
                        :items="permissionLevels"
                        item-text="name"
                        :menu-props="{
                          closeOnContentClick: false,
                          offsetY: true,
                        }"
                        dense
                        hide-details
                        :append-icon="iconVal"
                        :disabled="readOnly"
                        @focus="closeAlert"
                      >
                      </v-select>
                    </div>
                    <div
                      class="close-icon ml-10"
                      v-if="
                        header.text === 'Level' &&
                        enableDelete &&
                        !isInternalAdmin
                      "
                    >
                      <v-icon color="primary" @click="deleteRow(index)"
                        >mdi-close-circle-outline</v-icon
                      >
                    </div>
                  </div>
                  <div
                    class="d-flex align-center justify-between internal-switch"
                    v-if="header.text === 'Internal' && isInternalAdmin"
                  >
                    <v-switch
                      v-if="header.text === 'Internal'"
                      dense
                      color="primary"
                      v-model="userForm.permissions[index].internal"
                      :disabled="readOnly"
                      @change="handleChangeInternalSwitch(index)"
                    >
                    </v-switch>
                    <div class="close-icon ml-4" v-if="enableDelete">
                      <v-icon color="primary" @click="deleteRow(index)"
                        >mdi-close-circle-outline</v-icon
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </DataTable>
        <div
          class="d-flex justify-content-end pointer"
          v-if="createUser || editUser"
        >
          <div class="d-flex">
            <v-icon class="mr-2 mt-1" color="primary" @click="addRow"
              >mdi-plus</v-icon
            >
            <h5 class="mt-3 permission-label" @click="addRow">Add new row</h5>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-start mt-6">
        <v-btn
          v-if="createUser"
          :disabled="!enableSave"
          :loading="loadingNewUser"
          color="green"
          class="px-10 white--text mr-2"
          @click.prevent="createOrEditUser('new')"
          >Save</v-btn
        >
        <v-btn
          v-if="editUser"
          :disabled="!enableSave"
          :loading="loading.editUserDetails"
          color="green"
          class="px-8 white--text mr-2"
          @click.prevent="createOrEditUser('edit')"
          >Save changes</v-btn
        >
        <v-btn
          outlined
          class="px-8"
          @click="cancelCreation"
          v-if="createUser || editUser"
        >
          Cancel
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PhoneNumberInput from "@/components/PhoneNumberInput";
import DataTable from "@/components/DataTable";
import DataLoading from "@/components/DataLoading";
import timezones from "@/helpers/timezones";

export default {
  name: "UserDetailWidget",
  components: {
    PhoneNumberInput,
    DataTable,
    DataLoading,
  },
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    permissionHeaders: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      userForm: {
        name_first: null,
        name_last: null,
        email: null,
        phone: null,
        time_zone: null,
        permissions: [],
        is_active: true,
      },
      entities: [],
      adminOrgList: [],
      adminSiteList: [],
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v,
          ) || "E-mail must be valid",
      ],
      locationRules: [(v) => !!v || "Location is required"],
      isFormValid: false,
      isPhoneValid: false,
      permissionLevels: [
        {
          name: "Read (read only)",
          value: "read",
        },
        {
          name: "Write (read/write)",
          value: "write",
        },
        {
          name: "Admin (admin/read/write)",
          value: "admin",
        },
      ],
      errMessage: "",
      showAlert: false,
      locations: timezones,
      defaultKeyVal: 0,
      userLoaded: false,
      showSelectAll: false,
      pageLoading: false,
      loadingNewUser: false,
    };
  },
  computed: {
    ...mapState({
      adminUserData: (state) => state.user.userData,
      adminPermissions: (state) => state.admin.adminPermissions,
      adminOrgs: (state) => state.admin.adminOrgs,
      adminSites: (state) => state.admin.adminSites,
      loading: (state) => state.admin.loading,
      userDetails: (state) => state.admin.userDetails,
      createUserResponse: (state) => state.admin.createUserResponse,
      editUserResponse: (state) => state.admin.editUserResponse,
    }),
    editUser() {
      return this.isEdit;
    },
    createUser() {
      return this.isNew;
    },
    entityTypes() {
      if (this.loading.getAdminPermissions) {
        return ["Loading..."];
      } else if (this.adminOrgs.length > 0 && this.adminSites.length > 0) {
        return ["Org", "Site"];
      } else if (this.adminOrgs.length > 0 && this.adminSites.length === 0) {
        return ["Org"];
      } else if (this.adminOrgs.length === 0 && this.adminSites.length > 0) {
        return ["Site"];
      } else {
        return ["Check your permissions and try again."];
      }
    },
    isInternalAdmin() {
      if (
        this.adminPermissions?.internal &&
        this.adminUserData?.email &&
        this.adminUserData.email.includes("@windesco")
      ) {
        return true;
      } else {
        return false;
      }
    },
    enableSave() {
      if (
        this.userForm.name_first &&
        this.userForm.name_last &&
        this.userForm.email &&
        this.userForm.phone &&
        this.userForm.time_zone &&
        this.userForm.permissions.length > 0
      ) {
        const phoneIsValid = this.isPhoneValid;
        const permsAreValid = this.checkPermValidity();
        const formIsValid = this.$refs.newUserForm.validate();

        if (phoneIsValid && permsAreValid && formIsValid) {
          return true;
        } else {
          return false;
        }
      }

      return false;
    },
    enableDelete() {
      if (
        this.createUser ||
        (this.editUser && this.userForm.permissions.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    readOnly() {
      if (!this.createUser && !this.editUser) {
        return true;
      } else {
        return false;
      }
    },
    iconVal() {
      if (!this.createUser && !this.editUser) {
        return "";
      } else {
        return "$dropdown";
      }
    },
  },
  watch: {
    userDetails: {
      immediate: true,
      handler(data) {
        this.setInitialPermissions();
        if (data?.name_first || data?.email) {
          this.userLoaded = true;
        } else if (this.createUser) {
          this.userLoaded = true;
        } else if (
          this.loading.createNewUser ||
          this.loading.editUserDetails ||
          this.loading.getUserDetails
        ) {
          this.userLoaded = false;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      postNewUser: "admin/createNewUser",
      getUserDetails: "admin/getUserDetails",
      clearUser: "admin/clearUser",
      clearUserList: "userList/clearUserList",
    }),
    updateIsActive() {
      this.$emit("changeVal", this.userForm.is_active);
    },
    setFirstName(name) {
      this.userForm.name_first = name;
    },
    setLastName(name) {
      this.userForm.name_last = name;
    },
    setEmail(email) {
      this.userForm.email = email;
    },
    setUserLocation(location) {
      this.userForm.time_zone = location;
    },
    changePhone(phone, valid) {
      if (valid && phone) {
        this.userForm.phone = phone;
        this.isPhoneValid = true;
      } else {
        this.isPhoneValid = false;
      }
    },
    addRow() {
      if (this.userForm.permissions.length < 6) {
        this.userForm.permissions.push({
          entity_type: "",
          entity_id: [],
          level: "",
          internal: false,
          adminOrgs: [],
          adminSites: [],
          showSelectAll: false,
          permKey: `entity_${this.defaultKeyVal}`,
        });
        this.defaultKeyVal += 1;
      } else {
        this.errMessage = "Please limit permissions to 6 rows per user.";
        this.showAlert = true;
      }
    },
    deleteRow(index) {
      if (this.userForm.permissions.length > 1) {
        this.userForm.permissions.splice(index, 1);
      } else {
        this.userForm.permissions[index].entity_type = "";
        this.userForm.permissions[index].entity_id = [];
        this.userForm.permissions[index].level = "";
        this.userForm.permissions[index].internal = false;
        this.userForm.permissions[index].adminOrgs = [];
        this.userForm.permissions[index].adminSites = [];
      }
    },
    checkPermValidity() {
      let permsComplete = false;
      for (const perm of this.userForm.permissions) {
        if (!perm.entity_type || perm.entity_id.length === 0 || !perm.level) {
          permsComplete = false;
          break;
        } else {
          permsComplete = true;
        }
      }
      return permsComplete;
    },
    async createOrEditUser(mode) {
      let newUserObj = {
        name_first: "",
        name_last: "",
        email: "",
        phone_number: "",
        time_zone: "",
        is_active: "",
      };
      if (this.enableSave) {
        let permissionArr = [];

        newUserObj.name_first = this.userForm.name_first;
        newUserObj.name_last = this.userForm.name_last;
        newUserObj.email = this.userForm.email;
        newUserObj.phone_number = this.userForm.phone;
        newUserObj.time_zone = this.userForm.time_zone;
        newUserObj.is_active = this.userForm.is_active;

        for (const perm of this.userForm.permissions) {
          let permObj = {
            entity_type: perm.entity_type.toLowerCase(),
            internal: perm.internal,
          };
          let idArr = [];
          let starString = "";

          for (const entity of perm.entity_id) {
            if (entity.id === "*" || entity === "*") {
              starString = "*";
              break;
            } else if (typeof entity === "number") {
              idArr.push(entity);
            } else {
              idArr.push(entity.id);
            }
          }

          if (typeof perm.level === "string") {
            permObj.level = perm.level;
          } else {
            permObj.level = perm.level.value;
          }

          if (starString === "*") {
            permObj.entity_id = "*";
          } else {
            permObj.entity_id = idArr;
          }

          permissionArr.push(permObj);
        }

        newUserObj.permissions = permissionArr;

        if (mode === "new") {
          clearTimeout(createUserTimeout);
          this.loadingNewUser = true;
          await this.postNewUser(newUserObj);
          var createUserTimeout = setTimeout(() => {
            this.$router.push({ path: `/admin/users/${this.userDetails.id}` });
          }, 800);
          this.clearUserList();
        } else if (mode === "edit") {
          const userId = this.$route.params.userId;
          newUserObj.userId = userId;
          this.$emit("editUser", newUserObj);
        }
      }
    },
    setInitialPermissions() {
      this.pageLoading = true;
      if (this.loadingNewUser) {
        return;
      }
      if (!this.createUser && this.userDetails?.email) {
        this.userForm = {
          is_active: this.userDetails.is_active,
          name_first: this.userDetails.name_first,
          name_last: this.userDetails.name_last,
          email: this.userDetails.email,
          phone: this.userDetails.phone_number,
          time_zone: this.userDetails.time_zone,
          permissions: [],
        };
        if (this.userDetails?.permissions?.length > 0) {
          let showSelectAll = false;
          let permArr = [];
          let adminOrgList = [];
          let adminSiteList = [];

          for (let i = 0; i < this.userDetails.permissions.length; i++) {
            if (this.userDetails.permissions[i].entity_type === "Org") {
              // Set the org list to the wildcard only if the permission is a wildcard
              if (this.userDetails.permissions[i].entity_id[0] === "*") {
                adminOrgList = [{ id: "*", name: "All orgs (*)" }];
                showSelectAll = false;
              } else if (
                this.userDetails.permissions[i].entity_id[0] !== "*" &&
                this.adminPermissions.asterisk
              ) {
                adminOrgList = this.adminOrgs;
                showSelectAll = false;
              } else if (
                this.userDetails.permissions[i].entity_id[0] !== "*" &&
                !this.adminPermissions.asterisk
              ) {
                adminOrgList = this.adminOrgs;
                showSelectAll = true;
              }
            } else if (this.userDetails.permissions[i].entity_type === "Site") {
              adminSiteList = this.adminSites;
              showSelectAll = true;
            }
            permArr.push({
              entity_id: this.userDetails.permissions[i].entity_id,
              entity_type: this.userDetails.permissions[i].entity_type,
              level: this.userDetails.permissions[i].level,
              internal: this.userDetails.permissions[i].internal,
              permKey: this.userDetails.permissions[i].permKey,
              adminOrgs: adminOrgList,
              adminSites: adminSiteList,
              showSelectAll: showSelectAll,
            });
          }
          // Set the permissions displayed in the permissions table to the user's permissions
          this.userForm.permissions = permArr;
        }
      } else {
        // If a new user, set default row
        this.addRow();
      }
      this.pageLoading = false;
    },
    changeEntityType(event, index) {
      if (this.editUser) {
        if (this.userForm.permissions[index].entity_id.length > 0) {
          this.userForm.permissions[index].entity_id = [];
        }
        if (this.userForm.permissions[index].entity_type === "Org") {
          if (this.adminOrgs[0].id === "*") {
            this.userForm.permissions[index].showSelectAll = false;
          } else {
            this.userForm.permissions[index].showSelectAll = true;
          }
          this.userForm.permissions[index].adminOrgs = this.adminOrgs;
        } else if (this.userForm.permissions[index].entity_type === "Site") {
          this.userForm.permissions[index].adminSites = this.adminSites;
          this.userForm.permissions[index].showSelectAll = true;
        }
      } else {
        if (this.userForm.permissions[index].entity_type === "Org") {
          this.userForm.permissions[index].adminOrgs = this.adminOrgs;
          if (this.adminOrgs[0]?.id === "*") {
            this.userForm.permissions[index].showSelectAll = false;
          } else {
            this.userForm.permissions[index].showSelectAll = true;
          }
        } else if (this.userForm.permissions[index].entity_type === "Site") {
          this.userForm.permissions[index].adminSites = this.adminSites;
          this.userForm.permissions[index].showSelectAll = true;
        }
      }
    },
    changeEntitiesSelected(event, index) {
      if (
        event.includes("*") &&
        this.userForm.permissions[index].entity_type === "Org"
      ) {
        this.userForm.permissions[index].entity_id = ["*"];
        // Only internal users can be assigned a wildcard permission
        this.userForm.permissions[index].internal = true;
        this.userForm.permissions[index].adminOrgs = [
          { id: "*", name: "All orgs (*)" },
        ];
        if (!this.userForm.email.includes("@windesco")) {
          this.showAlert = true;
          this.errMessage =
            "Only users with an internal email can be assigned wildcards: All orgs (*)";
          this.userForm.email = "";
        }
      } else if (
        !event.includes("*") &&
        this.userForm.permissions[index].entity_type === "Org"
      ) {
        this.userForm.permissions[index].adminOrgs = this.adminOrgs;
        this.userForm.permissions[index].internal = false;
      }
    },
    handleChangeInternalSwitch(index) {
      this.showAlert = false;
      this.errMessage = "";
      if (
        this.userForm.permissions[index].entity_id.length > 0 &&
        this.userForm.permissions[index].entity_id[0] === "*"
      ) {
        this.showAlert = true;
        this.errMessage =
          "Only internal permissions can be assigned wildcard resources: All orgs (*).";
        this.$nextTick(
          () => (this.userForm.permissions[index].internal = true),
        );
      } else if (
        this.userForm.permissions[index].internal &&
        this.userForm.email &&
        !this.userForm.email.includes("@windesco")
      ) {
        this.showAlert = true;
        this.errMessage =
          "Only users with an internal email: (@windesco) can be assigned internal permissions.";
        this.$nextTick(
          () => (this.userForm.permissions[index].internal = false),
        );
      }
    },
    selectAllItems(index) {
      let siteIdArr = [];
      for (const org of this.adminSites) {
        siteIdArr.push(org.id);
      }
      this.userForm.permissions[index].entity_id = siteIdArr;
    },
    clearSelectedItems(index) {
      this.userForm.permissions[index].entity_id = [];
    },
    cancelCreation() {
      if (this.createUser) {
        this.$refs.newUserForm.reset();
        this.userForm.phone = "";
        this.userForm.permissions = [];
        this.addRow();
      } else {
        this.closeAlert();
        this.setInitialPermissions();
        this.$emit("cancelEdit");
      }
    },
    closeAlert() {
      this.showAlert = false;
      this.errMessage = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
}

.form_container .v-text-field {
  width: 400px;
}

.phone-input {
  width: 400px;
}

.permissions-table {
  margin: 0;
  max-width: 900px;
}

.permissions-table__title {
  font-size: 1.3rem;
}

.permission-select {
  width: 175px;
}

::v-deep {
  .remove-underline {
    .v-input__slot::before {
      border-style: none !important;
    }
  }
}

.save-icon {
  padding-left: 65px;
}

.new-permission__div {
  max-width: 700px;
}

// .permission-table__title {
//   font-size: 1.3rem;
// }

.permission-list {
  list-style: none;
}

.permission-row {
  border: 2px solid pink;
  border-radius: 4px;
}

.close-icon {
  width: 50px;
}

.success-dialog {
  position: absolute;
  top: 10%;
  left: 30%;
  width: 21rem;
  height: 3rem;
  font-size: 1.05rem;
  z-index: 2070;
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: #1c64ca;
  color: white;
  align-items: center;
  justify-content: center;
}

.success-dialog__text {
  margin: 0;
}
</style>
