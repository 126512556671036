<template>
  <div>
    <section class="mb-8 d-flex justify-space-between">
      <div class="d-flex align-center">
        <NameAvatar
          v-if="!isNew && !isEdit && pageTitle"
          :name="userInitials(pageTitle)"
          :color="stringToColor(pageTitle)"
          class="mr-2"
        />
        <h2 class="main-title mb-0">
          {{ pageTitle }}
        </h2>
      </div>
      <div>
        <v-btn color="primary" class="px-2 mr-2" @click="returnToUsers">
          <v-icon class="mr-2 mt-1" color="white" @click="returnToUsers"
            >mdi-arrow-left</v-icon
          >Back to users
        </v-btn>
        <v-btn
          color="primary"
          class="px-3 white--text mr-2"
          @click="editUserDetail"
          v-if="!isNew && !isEdit"
        >
          <v-icon class="mr-2 white--text">mdi-pencil</v-icon>Edit user
        </v-btn>
      </div>
    </section>
    <UserDetailWidget
      :isEdit="isEdit"
      :isNew="isNew"
      :userDetails="userInfo"
      :permissionHeaders="permissionHeaders"
      @editUser="callEditUser"
      @cancelEdit="cancelEditing"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import UserDetailWidget from "@/components/UserDetailWidget";
import { userInitials, stringToColor } from "@/helpers/functions";
import NameAvatar from "@/components/NameAvatar";

export default {
  name: "UserManager",
  components: {
    UserDetailWidget,
    NameAvatar,
  },
  data() {
    return {
      title: "",
      isEdit: false,
      isNew: false,
      userInitials,
      stringToColor,
      permissionHeaders: [
        {
          text: "Entity type",
          sortable: false,
          value: "entity_type",
        },
        {
          text: "Entities",
          sortable: true,
          value: "entity_id",
        },
        { text: "Level", sortable: false, value: "level" },
      ],
      isCreatedUser: false,
    };
  },
  destroyed() {
    this.clearUser();
  },
  async created() {
    const isUserCreation = this.$route.path.includes("/new");
    const userId = parseInt(this.$route.params.userId);

    if (isUserCreation) {
      this.title = "New user";
      this.isNew = true;
    }
    if (
      !this.adminPermissions ||
      Object.keys(this.adminPermissions).length === 0
    ) {
      await this.getAdminPermissions();
    }

    if (
      userId &&
      !isUserCreation &&
      this.userDetails.id !== userId &&
      !this.userDetails.from_create
    ) {
      await this.getUserDetails(userId);
    }
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.admin.userDetails,
      userData: (state) => state.user.userData,
      adminPermissions: (state) => state.admin.adminPermissions,
      loading: (state) => state.admin.loading,
    }),
    pageTitle() {
      if (
        this.userDetails &&
        "full_name" in this.userDetails &&
        !this.isNew &&
        !this.isEdit &&
        !this.loading.getAdminPermissions &&
        !this.loading.getUserDetails
      ) {
        return this.userInfo.full_name;
      } else if (!this.isNew && this.isEdit) {
        return "Edit user";
      } else {
        return this.title;
      }
    },
    userInfo() {
      if (this.adminPermissions && "internal" in this.adminPermissions) {
        return this.setPermissionHeaders();
      } else {
        return {};
      }
    },
  },
  methods: {
    ...mapActions({
      getUserDetails: "admin/getUserDetails",
      getAdminPermissions: "admin/getAdminPermissions",
      editUser: "admin/editUserDetails",
      clearUser: "admin/clearUser",
      getUserList: "userList/getUserList",
      clearUserList: "userList/clearUserList",
    }),
    setPermissionHeaders(mode) {
      let hasInternalHeader = false;
      if (
        "is_internal" in this.userData &&
        "internal" in this.adminPermissions &&
        !mode
      ) {
        for (const header of this.permissionHeaders) {
          if (header.text === "Internal") {
            hasInternalHeader = true;
            break;
          }
        }
        if (!hasInternalHeader) {
          this.permissionHeaders.push({
            text: "Internal",
            sortable: false,
            value: "internal",
          });
        }
      }

      return this.userDetails;
    },
    editUserDetail() {
      this.isEdit = true;
    },
    async callEditUser(editObj) {
      await this.editUser(editObj);
      this.setPermissionHeaders("edit_user");
      this.isEdit = false;
      this.clearUserList();
    },
    cancelEditing() {
      this.isEdit = false;
    },
    returnToUsers() {
      this.$router.push({ path: "/admin/users" });
      this.clearUser();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables";

.main-title {
  font-size: 1.75rem;
}

::v-deep {
  .input {
    height: 38px;
  }

  .v-label {
    margin-bottom: 0;
    color: var(--v-black3-base) !important;
  }
}
</style>
